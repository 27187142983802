import axios from "axios";
import apiUrl from "../config/apiUrl";

const headers = {
    Accept: "application/json",
};
const axiosInstance = axios.create({
    baseURL: apiUrl,
    headers: headers,
});

axiosInstance.interceptors.request.use(
    async (config: any) => {
        if (typeof window !== "undefined") {
            const token = localStorage.getItem("token");
            if (token) {
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${token}`,
                };
            }
        }
        return config;
    },
    (error: any) => {
        if (error.status === 401) {
            localStorage.removeItem("token");
            window.location.href = "/auth/login";
        }
        return Promise.reject(error);
    },
);

export default axiosInstance;
