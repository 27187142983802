export function toTitleCase(str: string): string {
    return str
        .replaceAll("-", " ")
        .replaceAll("_", " ")
        .replaceAll("/", " ")
        .replaceAll(".", " ")
        .replaceAll("  ", " ")
        .trim()
        .replace(/\b\w/g, (char) => char.toUpperCase())
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}

export function toSnakeCase(str: string): string {
    return str
        .replaceAll("-", " ")
        .replaceAll("_", " ")
        .replaceAll("/", " ")
        .replaceAll(".", " ")
        .replaceAll("  ", " ")
        .replace(/\s+/g, " ")
        .trim()
        .replace(" ", "_")
        .replace(/([a-z0-9])([A-Z])/g, "$1_$2") // Add underscore between camelCase words
        .replace(/[-\/.]/g, "_") // Replace hyphens, slashes, and dots with underscores
        .toLowerCase(); // Convert to lowercase
}

export function toKebabCase(str: string): string {
    return str
        .replaceAll("_", " ")
        .replaceAll("-", " ")
        .replaceAll("/", " ")
        .replaceAll(".", " ")
        .replaceAll("  ", " ")
        .trim()
        .replace(/\s+/g, " ")
        .replace(/([a-z0-9])([A-Z])/g, "$1-$2") // Add hyphen between camelCase words
        .replace(/[_\/.]/g, "-") // Replace underscores, slashes, and dots with hyphens
        .toLowerCase(); // Convert to lowercase
}

export function toSentenceCase (str: string) {
    return str
        .replaceAll("-", " ")
        .replaceAll("_", " ")
        .replaceAll("/", " ")
        .replaceAll(".", " ")
        .replaceAll("  ", " ")
        .trim()
        .charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}