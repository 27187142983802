
const useAuth = () => {
    const isLoggedIn = () => {
        if (typeof window === "undefined") return false;

        const token = localStorage.getItem("token");
        return !!token;
    };
    const setToken = (token: string) => {
        if (typeof window !== "undefined") {
            localStorage.setItem("token", token);
        }
    };
    const logout = () => {
        if (typeof window !== "undefined") {
            localStorage.removeItem("token");
        }
    };

    return { isLoggedIn, setToken, logout };
};
export default useAuth;
