import { useRouter } from "next/router";
import { useEffect } from "react";
import useAuth from "./useAuth";

const protectedRoutes = ["/dashboard", "/dashboard/change-password"];
const protectedWhenAuthenticated = ["/auth/login", "/auth/signup", "/auth/reset", "/auth/forget"];

export const useRouteProtection = () => {
    const router = useRouter();
    const { isLoggedIn } = useAuth();
    useEffect(() => {
        const currentPath = router.asPath.toLowerCase();
        const locale = router.locale ? `/${router.locale}` : "";

        if (!isLoggedIn() && protectedRoutes.includes(currentPath)) {
            window.location.href = `${locale}/auth/login`;
            return;
        }

        if (isLoggedIn() && protectedWhenAuthenticated.includes(currentPath)) {
            window.location.href = `${locale}/dashboard`;
            return;
        }
    }, [isLoggedIn, router.asPath, router.locale]);
};
