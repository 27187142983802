import { HTMLAttributes, ReactNode } from "react";
import { usePopup } from "./usePopup";
import { twMerge } from "tailwind-merge";

export interface PopupProps {
    isOpen: boolean;
    content: ReactNode;
    children: ReactNode;
    close?: () => void;
    className?: HTMLAttributes<HTMLDivElement>["className"];
    isShrinked?: boolean;
}

/**
 * pass in a children with absolute position to make this work
 * this was done to make the popup more flexible in terms of positioning
 */
export const Popup = ({ isOpen, close, children, content, className, isShrinked }: PopupProps) => {
    const { parentRef } = usePopup(isOpen, close);

    return (
        <div className={twMerge("relative z-10", className)} ref={parentRef}>
            <div className={`flex items-center ${isShrinked ? "justify-start" : "justify-center"}`}>{children}</div>
            {isOpen && <div>{content}</div>}
        </div>
    );
};
