import { ReactNode } from "react";
import { Translate } from "components/common/Translate/Translate";
import { RouteNames } from "@/RouteNames";

export interface NavLinkProps {
    title: string | ReactNode;
    link: string;
    isEditor?: boolean;
    outlink?: boolean;
    isChild?: boolean;
    subItems?: NavChild[] | NavChild[][];
    urlMatcher?: (url: string) => boolean;
}

export interface NavChild {
    title: string | ReactNode;
    link: string;
    outlink?: boolean;
    urlMatcher?: (url: string) => boolean;
}

export const navLinks: NavLinkProps[] = [
    {
        title: <Translate keyName={"links:image-resizer"} />,
        link: "/",
        urlMatcher: (url) => {
            return url === "/resize/editor/[task-id]";
        },
    },

    {
        title: <Translate keyName={"image-cropper"} />,
        link: "/crop-image",
        urlMatcher: (url) => url === "/crop-image/editor",
    },
    {
        title: <Translate keyName={"links:image-compressors"} />,
        link: "/",
        subItems: [
            {
                title: <Translate keyName="image-compressor" />,
                link: RouteNames.imageCompressor,
                urlMatcher: (url) => url.includes("image-compressor"),
            },
            {
                title: <Translate keyName="jpeg-compressor" />,
                link: RouteNames.jpegCompressor,
                urlMatcher: (url) => url.includes("compress-jpeg"),
            },
            {
                title: <Translate keyName="png-compressor" />,
                link: RouteNames.pngCompressor,
                urlMatcher: (url) => url.includes("png-compressor"),
            },
            {
                title: <Translate keyName="gif-compressor" />,
                link: RouteNames.gifCompressor,
                urlMatcher: (url) => url.includes("gif-compressor"),
            },
        ],
        urlMatcher: (url) => url.includes("compressor"),
    },
    {
        title: <Translate keyName={"image-converters"} />,
        link: "",
        urlMatcher: (url) => url === "/image-converter/editor",
        subItems: [
            [
                {
                    title: <Translate keyName={"image-converter"} />,
                    link: "/image-converter",
                    urlMatcher: (url) => url === "/image-converter/editor",
                },
                {
                    title: <Translate keyName={"format-converter"} variables={{ format: "SVG" }} />,
                    link: "/svg-converter",
                    urlMatcher: (url) => url === "/svg-converter/editor",
                },
                {
                    title: <Translate keyName={"format-converter"} variables={{ format: "PNG" }} />,
                    link: "/png-converter",
                    urlMatcher: (url) => url === "/png-converter/editor",
                },
                {
                    title: <Translate keyName={"format-converter"} variables={{ format: "JPG" }} />,
                    link: "/jpg-converter",
                    urlMatcher: (url) => url === "/jpg-converter/editor",
                },
                {
                    title: <Translate keyName={"format-converter"} variables={{ format: "GIF" }} />,
                    link: "/gif-converter",
                    urlMatcher: (url) => url === "/gif-converter/editor",
                },
            ],
            [
                {
                    title: <Translate keyName={"src-to-target"} variables={{ src: "HEIC", target: "JPG" }} />,
                    link: "/heic-to-jpg",
                    urlMatcher: (url) => url === "/heic-to-jpg/editor",
                },

                {
                    title: <Translate keyName={"src-to-target"} variables={{ src: "WebP", target: "PNG" }} />,
                    link: "/webp-to-png",
                    urlMatcher: (url) => url === "/webp-to-png/editor",
                },

                {
                    title: <Translate keyName={"src-to-target"} variables={{ src: "WebP", target: "JPG" }} />,
                    link: "/webp-to-jpg",
                    urlMatcher: (url) => url === "/webp-to-jpg/editor",
                },

                {
                    title: <Translate keyName={"src-to-target"} variables={{ src: "PNG", target: "JPG" }} />,
                    link: "/png-to-jpg",
                    urlMatcher: (url) => url === "/png-to-jpg/editor",
                },

                {
                    title: <Translate keyName={"src-to-target"} variables={{ src: "PNG", target: "SVG" }} />,
                    link: "/png-to-svg",
                    urlMatcher: (url) => url === "/png-to-svg/editor",
                },
            ],
        ],
    },
    {
        title: <Translate keyName={"bulk-resize"} />,
        link: "/bulk-resize",
        urlMatcher: (url) => url === "/bulk-resize/editor",
    },
    {
        title: <Translate keyName={"links:pricing"} />,
        link: "/pricing",
        urlMatcher: (url) => {
            return url === "/pricing";
        },
    },
    {
        title: (
            <span className="capitalize">
                <Translate keyName={"more"} />
            </span>
        ),
        link: "/",
        subItems: [
            {
                title: <Translate keyName="meme-generator" />,
                link: "/meme-generator",
            },
            {
                title: <Translate keyName="color-picker" />,
                link: "/color-picker",
            },
            {
                title: <Translate keyName="rotate-image" />,
                link: "/rotate-image",
            },
            {
                title: <Translate keyName="flip-image" />,
                link: "/flip-image",
            },
            {
                title: <Translate keyName="image-enlarger" />,
                link: "/image-enlarger",
                urlMatcher: (url) => url === "/image-enlarger/editor",
            },
        ],
    },
];
