import tools from "@/config/toolsLinks";
import { Translate } from "components/common/Translate/Translate";
import dynamic from "next/dynamic";
import Link from "@/components/common/Link";
import { useRouter } from "next/router";
import translatedRoutedSlugs from "translatedRouteSlugs";

const ContactUs = dynamic<{ title: string }>(
    import("./ContactUs").then((mod) => mod.ContactUs),
    { ssr: false },
);

export const Tools = () => {
    const { locale } = useRouter();

    return (
        <ul className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-5 px-8 lg:px-16 mt-auto border-t-slate-200 dark:border-t-darkSurface-600 border-t py-8 lg:py-16 gap-8 text-black dark:text-white">
            {tools.map((info, index) => {
                return (
                    <li key={index} className="flex flex-col gap-4 text-center">
                        <div className="font-bold">
                            <Translate keyName={info.header} />
                        </div>
                        <ul className="flex flex-col gap-4">
                            {info.links.map((link, index) => {
                                if (link.link === "/contact-us") {
                                    return <ContactUs key={link.title} title={link.title} />;
                                }
                                const translatedRoute = (translatedRoutedSlugs as any)[link.link]?.[locale ?? "en"] ?? link.link;
                                const href = link.link.includes("http")
                                    ? link.link
                                    : ((translatedRoute !== null ? translatedRoute : null) ?? link.link);

                                return (
                                    <li key={index}>
                                        <Link
                                            href={href}
                                            target={link.link.includes("http") ? "_blank" : "_self"}
                                        >
                                            <Translate keyName={link.title} />
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                );
            })}
        </ul>
    );
};
