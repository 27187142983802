import { CloseIcon } from "components/common/Icons/CloseIcon";
import { HamburgerIcon } from "components/common/Icons/HamburgerIcon";
import { IRLogo } from "components/common/Icons/IRLogo";
import { Modal } from "components/common/Modal/Modal";
import { MobileNavLink } from "./MobileNavLink/MobileNavLink";
import { useToggle } from "client/hooks/utils/useToggle";
import type { LanguageProps } from "components/modules/Layout/Footer/Footer";
import { MobileTheme } from "./MobileTheme/MobileTheme";
import { MobileLanguage } from "./MobileLanguage/MobileLanguage";
import { navLinks } from "components/modules/Navigation/navLinks";
import dynamic from "next/dynamic";
const UserAccountMenu = dynamic<{}>(
    () => import("components/modules/Navigation/Navbar/Account/UserAccountMenu").then((mod) => mod),
    { ssr: false },
);

interface MobileNavigationProps extends LanguageProps {
    isEditor: boolean;
}

export const MobileNavigation = ({ isEditor, url, urlSlugVariables }: MobileNavigationProps) => {
    const { isOpen, open: openModal, onClose: closeModal } = useToggle(false);
    return (
        <div className="lg:hidden">
            <div className="cursor-pointer overflow-hidden mr-2" onClick={openModal}>
                <HamburgerIcon />
            </div>
            <Modal isOpen={isOpen} close={closeModal}>
                <div className="w-full h-full bg-slate-900/50" onClick={closeModal}>
                    <div
                        className="w-full bg-white dark:bg-darkSurface-100 z-50 text-black"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* header */}
                        <div className="flex items-center p-4 border-b-slate-200/100 dark:border-b-darkSurface-300 border-b">
                            <IRLogo isEditor={isEditor} />
                            <div className="ml-auto cursor-pointer" onClick={closeModal}>
                                <CloseIcon />
                            </div>
                        </div>
                        <ul className="flex flex-col items-center ml-auto">
                            {navLinks.map((e, index) => {
                                return <MobileNavLink key={index} {...e} isEditor={isEditor} />;
                            })}
                            <UserAccountMenu />
                            <MobileTheme />
                            <MobileLanguage url={url} urlSlugVariables={urlSlugVariables} />
                        </ul>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
