import { DesktopNavLink } from "./DesktopNavLink/DesktopNavLink";
import { navLinks } from "components/modules/Navigation/navLinks";
import dynamic from "next/dynamic";
const UserAccountMenu = dynamic<{}>(
    () => import("components/modules/Navigation/Navbar/Account/UserAccountMenu").then((mod) => mod),
    { ssr: false },
);

interface DesktopNavigationProps {
    isEditor: boolean;
}

export const DesktopNavigation = ({ isEditor }: DesktopNavigationProps) => {
    return (
        <ul className="hidden lg:flex items-center ml-auto">
            {navLinks.map((e, index) => (
                <DesktopNavLink key={index} {...e} isEditor={isEditor} />
            ))}
            <li>
                <UserAccountMenu />
            </li>
        </ul>
    );
};
