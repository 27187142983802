export class RouteNames {
    public static readonly imageCompressor = "/image-compressor";
    public static readonly pngCompressor = "/png-compressor";
    public static readonly gifCompressor = "/gif-compressor";
    public static readonly jpegCompressor = "/compress-jpeg";
    public static readonly imageConverter = "/image-converter";
    public static readonly imageEnlarger = "/image-enlarger";
    public static readonly cropImage = "/crop-image";
    public static readonly rotateImage = "/rotate-image";
    public static readonly flipImage = "/flip-image";
    public static readonly bulkResize = "/bulk-resize";
    public static readonly colorPicker = "/color-picker";
    public static readonly imageResizer = "/";
    public static readonly loginRoute = "/auth/login";
    public static readonly signupRoute = "/auth/signup";
    public static readonly forgotPasswordRoute = "/auth/forgot";
    public static readonly resetPasswordRoute = "/auth/reset";
    public static readonly changePasswordRoute = "/dashboard/account/change-password";
    public static readonly dashboardRoute = "/dashboard";
    public static readonly accountRoute = "/dashboard/account";
    public static readonly subscriptionRoute = "/dashboard/billing/subscriptions";
    public static readonly billingResultRoute = "/dashboard/billing/result";
    public static readonly pricing = "/pricing";

}
