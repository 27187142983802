import Link from "next/link";
import { Internationalization } from "../../Internationalization/Internationalization";
import links from "client/config/links";
import { Translate } from "components/common/Translate/Translate";
import translatedRoutedSlugs from "translatedRouteSlugs";
import { useRouter } from "next/router";
import { Theme } from "../../Navigation/Navbar/Theme/Theme";

export interface LanguageProps {
    url: keyof typeof translatedRoutedSlugs;
    urlSlugVariables?: Record<string, string>;
}

const VERSION = `v.${process.env["NEXT_PUBLIC_APPLICATION_VERSION"]}`;

export const Footer = ({ url, urlSlugVariables }: LanguageProps) => {
    const { locale } = useRouter();
    return (
        <div className="flex flex-col min-w-0">
            {/* bottom bar */}
            <div className="flex flex-col gap-4 py-8 text-black bg-gray-100 lg:flex-row-reverse lg:items-center dark:bg-darkSurface-200 dark:text-white">
                <div className="flex flex-col w-full gap-4 px-8 lg:flex-row-reverse">
                    {/* internationalization */}
                    <div>
                        <Internationalization url={url} urlSlugVariables={urlSlugVariables} />
                    </div>
                    <Theme onlyIconMode={false} />
                    <ul className="flex flex-col gap-4 lg:flex-row">
                        {links.map((link, index) => {
                            const translatedRoute = (translatedRoutedSlugs as any)[link.link]?.[locale ?? "en"];
                            const href = link.link.includes("http")
                                ? link.link
                                : ((translatedRoute !== null ? "/" + translatedRoute : null) ?? link.link);
                            return (
                                <li key={index}>
                                    <Link href={href}>
                                        <Translate keyName={link.title} />
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                {/* copyright */}
                <div className=" px-2 md:px-8 py-4 border-t border-t-slate-200 dark:border-t-darkSurface-400 lg:border-t-0 whitespace-nowrap text-sm sm:text-base text-center xl:text-start">
                    {`© ${new Date().getFullYear()} ImageResizer `}{" "}
                    <Link
                        href={`/changelogs#${VERSION}`}
                        target="_blank"
                        className="text-irBlue-600 dark:text-irBlue-200"
                    >
                        {VERSION}
                    </Link>
                    {". "}
                    <Translate keyName="all-rights-reserved" />
                    {"."}
                </div>
            </div>
        </div>
    );
};
