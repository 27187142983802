import { IRLogo } from "components/common/Icons/IRLogo";
import { DesktopNavigation } from "../DesktopNavigation/DesktopNavigation";
import { MobileNavigation } from "../MobileNavigation/MobileNavigation";
import type { LanguageProps } from "components/modules/Layout/Footer/Footer";

interface NavbarProps extends LanguageProps {}

export const Navbar = ({ url, urlSlugVariables }: NavbarProps) => {
    return (
        <div className="flex flex-row items-center h-16 px-4 py-4 border-b border-b-slate-200 dark:border-b-darkSurface-600 md:px-8">
            <IRLogo isEditor={false} />
            <DesktopNavigation isEditor={false} />
            <div className="ml-auto lg:hidden">
                <MobileNavigation url={url} urlSlugVariables={urlSlugVariables} isEditor={false} />
            </div>
        </div>
    );
};
